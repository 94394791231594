<template>
  <div class="container">
    <form class="form-forgot" ref="form-forgot" @submit.prevent="forgotPassword">
      <div class="form-control">
        <input v-model="login" type="email" name="email" placeholder="Email" />
      </div>
      <div class="form-forgot__actions">
        <button type="submit" class="button button-primary">
          Get Password Reset Link
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { showSnackbar } from '@/services'
import mtApi from '../agGridV2/helpers/mt-api.helper'

export default {
  name: 'Forgot Password',

  data() {
    return {
      login: ''
    }
  },
  methods: {
    ...mapMutations(['setViewLoader']),

    async forgotPassword() {
      try {
        this.setViewLoader(true)
        const response = await mtApi.forgotPassword({
          email: this.login
        })
        showSnackbar({
          mode: 'success',
          text: response.message
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.setViewLoader(false)
        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-forgot {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;
  padding: 60px;
  background-color: $bg-secondary;

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
}
.form-control + .form-control {
  margin-top: 1rem;
}
</style>
